.figures_button {
    padding: 2rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    flex-direction: column;
    gap: 3rem;
    color: black;
    position: relative;
}

.text-f {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.text-f>span:nth-of-type(1) {
    font-size: 3rem;
    font-weight: bold;
}

.text-f>span:nth-of-type(2) {
    font-size: 1rem;
    max-width: 50%;
    text-align: center;
    color: var(--gray);
}

.figures {
    display: flex;
    gap: 5rem;
}

.figures>div {
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1) {
    color: black;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2) {
    color: var(--gray);
    text-transform: uppercase;
}

.hero-buttons {
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.hero-buttons>:nth-child(1) {
    color: white;
    background-color: var(--orange);
    width: 8rem;
}

.hero-buttons>:nth-child(2) {
    color: black;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}


.figures>div:hover {
    transform: translateY(-0.4rem);
    transition: all 0.3s ease-in-out;
}


@media screen and (max-width: 768px) {
    .text-f>span:nth-of-type(2) {
        max-width: 95%;
    }

    .text-f>span:nth-of-type(1) {
        font-size: 2.5rem;
        font-weight: bold;
    }

    .figures {
        flex-direction: column;
        gap: 3rem;
    }

    .figures>div:hover {
        transform: translateY(-0.4rem);
        transition: all 0.3s ease-in-out;
    }

}
